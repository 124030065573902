<template>
  <div class="item"  @click="toStore(item)">
    
      <div class="icon_h">

     
    <img class="icon" :src="item.thumbnailPic" />
     <div class="total"  v-show="item.contentTotal > 0">
       {{ item.contentTotal }}
      </div>
       <div class="transfer"  v-show="item.transferType != null">
       <div class="user-menu" >
          <div class="menu-img">
            <img class="m-img" src="@/assets/zz.svg" />
          </div>
          <div class="order_text">{{ item.transferType }}</div>
        </div>
      </div>
      </div>
    <div class="content">
      <span class="title">{{ item.title }}</span>
      <!-- <div class="code"  v-show="item.contentTotal == null" > -->
      <div class="code"   v-if="item.contentTotal == null">
        <img src="@/assets/content_back.png" />
        <span>{{ item.contentCode }}</span>
      </div>
      <div class="bottom">
        <div class="bottom_left">
          <img src="@/assets/local_icon.png" />
          <span>{{ item.localSource }}</span>
        </div>
         <img class="bottom_right_model" v-if="item.hasAr" @click.stop="showModelBtnNew(item.hasAr,item.contentId)" src="@/assets/3dmodel.svg" />
        <img class="bottom_right" @click.stop="showTypeBtnNew(item.hasAr,item.contentId)" :src="getUrl(item.showType,item.hasAr)" />
        
     
       
              <!-- <wx-open-launch-weapp id="launch-btn" username="gh_48e8d2ba9727" path="pages/index/index"
              @launch="handleLaunchFn"
                @error="handleErrorFn">
        <div v-is="'script'" type="text/wxtag-template">
             <button  style="width: 200px; height: 45px; text-align: center; font-size: 17px; display: block; margin: 0 auto; padding: 8px 24px; border: none; border-radius: 4px; background-color: #07c160; color:#fff;">打开小程序</button> 
         </div>
    </wx-open-launch-weapp> -->

        
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import axios from "../../../request/request";
import { Toast } from "vant";
import { Dialog } from "vant";
  import wx from "weixin-jsapi";

export default {
  props: ["item"],
  setup() {
 const router = useRouter();
     const toStore = (item) => {
       if(item.type === "0"){
         if(item.transferType != null){
          sessionStorage.setItem("goodsInfoData", JSON.stringify(item));
          router.push({ path: "./donationInfo"});
         }else{
           router.push({ path: "./userGoodsSingle", query: { contentId: item.contentId, isFinished:item.isFinished} }); 
         }
       }else if(item.type === "2"){
          if(item.transferType != null){
           sessionStorage.setItem("goodsInfoData", JSON.stringify(item));
          router.push({ path: "./donationInfo"});
         } else if(item.isFinished === 1){//未上链的
            router.push({ path: "./userGoodsSingle", query: { contentId: item.contentId, isFinished:item.isFinished} }); 
         }else{
           router.push({ path: "./userGoodsSeries", query: { contentId: item.contentId, isFinished:item.isFinished} }); 
         }
        
       }else{
         if(item.transferType != null){
          sessionStorage.setItem("transferDetail", JSON.stringify(item));
          router.push({ path: "./transferDetail"});
         }else{

        console.log("isFinished---------"+item.isFinished)
      if(item.isFinished === 3){
       console.log("isFinished---这里------"+item.isFinished)
          Toast.loading({
            message: '加载中...',
            forbidClick: true,
            });
     const userOpenid = JSON.parse(
        window.sessionStorage.getItem("userOpenid")
      );
     var THIS =this
     axios.post("app/luckBlindBox", {
          id: userOpenid.id,
          cid: item.cid,
        }).then(function (res) {
          if (res.status === 200 && res.data.retCode === 0) {
           
           Dialog.alert({
          title: '盲盒开启成功',
           message: '您已成功获得  '+ res.data.data.title+"  请在首页查看",
             theme: 'round-button',
             }).then(() => {
                      // on close
                      router.go(0)
               location.reload()
               });
           
            Toast.clear();
          } else {
            Toast.fail("抽奖失败" + res.data.retMsg);
             
          }
        })
        .catch(function (error) {
          Toast.fail("请检查网络"+error);
         
        });
     console.log("盲盒抽奖")
       return;
      }

          router.push({ path: "./goodsInfo", query: { mid: item.cid } });
         }
         
       }
      //   if(item.type == 0){
      //   router.push({ path: "./userGoodsSingle", query: { contentId: item.contentId, isFinished:item.isFinished} }); 
      //  }
      //   if(item.isFinished == 1){
      //   router.push({ path: "./goodsInfo", query: { mid: item.cid } }); 
      //  }
       //router.push({ path: "./goodsInfo", query: { mid: item.cid } });
    };
     return {
      toStore,
    };
  },
  data() {
    return {};
  },
  methods: {
    getUrl(type,hasAr) {
      if(hasAr == true){
          return require("@/assets/AR_icon.svg");
      }
      if (type == "img") {
        return require("@/assets/img_icon.png");
      }
      if (type == "3d") {
        return require("@/assets/3d_icon.png");
      }
      if (type == "video") {
        return require("@/assets/video_icon.svg");
      }
    },

showTypeBtnNew(hasAr,cid){
       console.log("hasAr--"+hasAr)
    
         if(hasAr == true){
           Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
          axios
          .post("app/mpUrlLink", {
             cid: cid,
          })
          .then(async (res) => {
       if (res.status === 200 && res.data.retCode === 0) {
           Toast.clear();
        
             console.log(res.data.data)
           
             window.location.href= res.data.data.url_link;


	        } else {
            Toast.fail(res.data.retMsg);
	        }

             })
          .catch(function (error) {
            // 请求失败处理
             console.log(error);
            Toast.fail("请检查网络");
          
          });
        }


  },
showModelBtnNew(hasAr,cid){
  console.log("hasAr-111-"+hasAr)
    
         if(hasAr == true){
           Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
          axios
          .post("app/modelUrl", {
             cid: cid,
          })
          .then(async (res) => {
       if (res.status === 200 && res.data.retCode === 0) {
           Toast.clear();
        
             console.log(res.data.data)
           
             window.location.href= res.data.data.model_url;


	        } else {
            Toast.fail(res.data.retMsg);
	        }

             })
          .catch(function (error) {
            // 请求失败处理
             console.log(error);
            Toast.fail("请检查网络");
          
          });
        }

},
  showTypeBtn(hasAr){
       console.log("hasAr--"+hasAr)
        var currentWebUrl =self.location.href;
         console.log("currentWebUrl--"+currentWebUrl)
           console.log("currentWebUrl--"+self.location.href.split('#')[0])
         if(hasAr == true){
           Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
          axios
          .post("app/wxJsConfigData", {
             sUrl: self.location.href.split('#')[0],
          })
          .then(async (res) => {
       if (res.status === 200 && res.data.retCode === 0) {
           Toast.clear();
             var wxData = res.data.data;
             console.log(wxData.appId)
            //  Toast(wxData.appId)
             window.location.href= "https://wxaurl.cn/r7fmppmIBvc"  

              wx.config({
                debug: true, //这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
                appId: wxData.appId,
                timestamp: wxData.timestamp,
                nonceStr: wxData.nonceStr,
                signature: wxData.signature,
                //  jsApiList: ["chooseWXPay"],
                jsApiList: ['chooseImage', 'previewImage'],
                // jsApiList: ["openLocation"],
                 openTagList:['wx-open-launch-weapp'], // 填入打开小程序的开放标签名
              });

        wx.ready(function() {
	            console.log("ready success");
                Toast("ready success")
                 var btn = document.getElementById("launch-btn");
            btn.addEventListener("launch", function (e) {
               Toast("打开成功");
            });
            btn.addEventListener("error", function (e) {
              Toast("打开失败");
            });

	          });
	          wx.error(function() {
	            console.log("error fail");
                  Toast("error fail")
	          });
	        } else {
             Toast("错")
	      
	        }

             })
          .catch(function (error) {
            // 请求失败处理
             console.log(error);
            Toast.fail("请检查网络");
          
          });
        }


  },
      luckBlindBox(item) {
      
    },
  },
};
</script>

<style lang='less' scoped>
.item {
  display: flex;
  margin: 0;
  padding: 0;
  background: rgba(50, 50, 50, 1);
  border-radius: 10px;
  display: flex;
  width: 170px;
  height: auto;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  .icon_h{
    width: 170px;
    height: 155px;
    position: relative;
     .icon {
    border: none;
    margin:0px;
    width: 170px;
    height: 155px;
    border-radius: 10px 10px 0px 0px;
    // border-radius: 10px;
  }
   .total{
   position: absolute;
   top: 0;
   right: 0; 
   width: 39px;
   height: 17px;
   background: linear-gradient(90deg, rgba(0, 219, 222, 1) 0%, rgba(252, 0, 255, 1) 100%);
  border-radius: 0px 10px 0px 10px;
   color: rgba(255, 255, 255, 1);
   font-size: 10px;
   line-height: 17px;
   text-align: center;
 .s_total{
   color: rgba(255, 255, 255, 1);
   font-size: 10px;
 }
    }

    .transfer{
    position: absolute;
   top: 0;
   right: 0; 
       width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
 //  background: linear-gradient(90deg, rgba(0, 219, 222, 1) 0%, rgba(252, 0, 255, 1) 100%);
  // border-radius: 0px 10px 0px 10px;
   color: rgba(255, 255, 255, 1);
  
   text-align: center;
  .user-menu {
        display: flex;
        justify-content: center;
        flex-flow: column;
        align-items: center;
        width: 100%;
        height: 100%;
        color: rgba(131, 131, 131, 1);
        .menu-img {
          width: 39px;
          height: 39px;
          .m-img{
          width: 39px;
          height: 39px;
          }
        }
        .order_text {
          color: rgba(255, 255, 255, 1);
          font-size: 12px;
        }
      }
    }
  }
 
  .content {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    .title {
      width: 149px;
      height: 15px;
      color: rgba(255, 255, 255, 1);
      font-size: 10px;
      font-weight: bolder;
      margin-top:7px;
      overflow: hidden;//溢出隐藏
      white-space: nowrap;//禁止换行
      text-overflow: ellipsis;//...
      display: block;
    }
    .code {
      position: relative;
      width: 154px;
      height: 21px;
      display: flex;
      margin-top: 4px;
      align-items: center;
      justify-content: start;
      img {
        margin: 0;
        padding: 0;
        width: 154px;
        height: 21px;

        position: relative;
      }
      span {
        position: absolute;
        width: 154px;
        height: 12px;
        margin-left: 21px;
        vertical-align: baseline;
        color: rgba(113, 72, 12, 1);
        line-height: 12px;
        font-size: 7px;
      }
    }
    .bottom {
      margin: 0;
      padding: 0;
      margin-top: 9px;
      margin-bottom: 6px;
      width: 148px;
      height: 15px;
      display: flex;
      align-items: center;
      .bottom_left {
        width: 86px;
        height: 15px;
        display: flex;
        img {
          width: 15px;
          height: 15px;
        }
        span {
          width: 70px;
          height: 15px;
          text-align: left;
          vertical-align: baseline;
          color: rgba(232, 193, 142, 1);
          font-size: 10px;
          margin-left: 4px;
          overflow: hidden;//溢出隐藏
          white-space: nowrap;//禁止换行
          text-overflow: ellipsis;//...
          display: block;
        }
      }
       .bottom_right_model{
        display: flex;
        margin-right: -35px;
        margin-left: 10px;
        width: 22px;
        height: 22px;
      }
      .bottom_right {
        display: flex;
        margin-left: 45px;
        width: 22px;
        height: 22px;
      }
        
    }
  }
}
</style>